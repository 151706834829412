:root {
  --color-primary: #181a1b;
  --color-secondary: #212628;
  --color-tertiary: #d1cdc7;
  --color-quaternary: #434343;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: var(--color-primary);
  color: var(--color-tertiary);
}

.light-theme {
  background-color: var(--color-tertiary);
  color: var(--color-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#theme-button {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 1;
}

#expand-all-button {
  position: absolute;
  top: 13%;
  right: 1%;
  z-index: 1;
}

#sharing-context {
  align-self: center;
  margin-top: 2%;
}

#sharing-context > button {
  margin-left: 5px;
}

#result-group {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: 5% auto 10px auto;
  width: 50%;
}

#result-group > button {
  width: 50%;
}

#cookie-modal-dark {
  background-color: var(--color-primary);
  color: var(--color-tertiary);
}

#cookie-modal-light {
  background-color: var(--color-tertiary);
  color: var(--color-primary);
}

.bottom-cookie-bar {
  position: fixed;
  bottom: 0;
  width: 90%;
  left: 5%;
}

#cookie-bottom-dark > * {
  background-color: var(--color-secondary);
  color: var(--color-tertiary);
}

#cookie-bottom-dark > .toast-header {
  border-bottom: black solid 1px;
}

#cookie-bottom-light > * {
  background-color: var(--color-tertiary);
  color: var(--color-primary);
}

#cookie-bottom-light > .toast-header {
  border-bottom: var(--color-quaternary) solid 1px;
}

#settings-icon {
  position: absolute;
  top: 7%;
  right: 1%;
  z-index: 1;
}

#home-container {
  min-height: 80%;
}

#footer {
  margin-top: 20px;
  width: 100%;
  border-top: 1px solid var(--color-quaternary);
}

.footer-dark {
  background-color: var(--color-secondary);
}

.footer-light {
  background-color: white;
}

.footer-home {
  position: absolute;
  bottom: 0;
}

#footer-logo {
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#footer-table {
  text-align: center;
}

.footer-table-dark {
  color: var(--color-tertiary);
}

.category-title {
  text-align: center;
  margin-top: 20px;
}